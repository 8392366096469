.testimonial-content {
  display: flex;
  flex-direction: column;
  background-color: #c5b7c9;
  margin-top: 75px;
  padding: 25px 0px;
}

.testimonial-title {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 9vw;
  color: #181818;
  margin-left: 40px;
}

.testimonial-section-A {
  background-color: #754480;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 15px;
  margin: 20px 0;
  width: 100vw;
}

.testimonial-section-B {
  background-color: #5b83b4;
  display: flex;
  justify-content: center;
  padding: 15px;
  margin: 20px 0;
  width: 100vw;
}

.testimonial-text {
  margin-left: 10px;
  font-size: 5vw;
  color: #ffffff;
}

@media (min-width: 600px) {
  .testimonial-title {
    margin-left: 40px;
    font-size: 7vw;
  }

  .testimonial-text {
    font-size: 4vw;
  }
}

@media (min-width: 768px) {
  .testimonial-text {
    font-size: 3vw;
  }
}

@media (min-width: 992px) {
  .testimonial-title {
    font-size: 45px;
  }

  .testimonial-text {
    font-size: 20px;
    max-width: 800px;
    margin: 10px auto;
  }
}

@media (min-width: 1400px) {
  .testimonial-title {
    margin: 0 auto;
    max-width: 1100px;
  }

  .testimonial-text {
    max-width: 1100px;
  }

  .testimonial-section-A {
    padding: 100px;
    max-height: 750px;
    margin: 50px 0;
  }
  
  .testimonial-section-B {
    padding: 100px;
    max-height: 750px;
    margin: 50px 0;
  }
}